.CoverRoot {
    height: calc(100vh - 70px);
    background-color: #800000;
    box-shadow: inset 0px 0px 500px 80px rgba(0,0,0,0.6);
    transition: 0.3s background;
}

.CoverCanvas {
    position: absolute;
}

.CoverCanvas > canvas {
    opacity: 0;
    transition: 0.5s opacity;
}

.CoverContent {
    width: calc(100vw - 650px);
    position: relative;
}

.CoverContentTitle {
    color: rgba(255,255,255,0.9);
    font-size: 2.3vw;
    text-transform: uppercase;
	font-family: 'GothamRounded Medium';
    line-height: 3.4vw;
    width: 560px;
    padding: calc(50vh - 160px) 0px 30px 120px;
}

.big {
    font-size: 4vw;
    padding-top: 10px;
}

.CoverContentButton {
    width: 130px;
    line-height: 55px;
    margin-left: 120px;
    padding: 0px 20px;
    text-align: center;
    background-color: rgba(255,255,255,0.85);
    color: #800;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    transition: 0.1s background;
	font-family: 'GothamRounded Light';
}

.CoverContentButton:hover {
    background-color: rgba(255,255,255,1);
}

@media (orientation: portrait) {
    .CoverRoot {
        height: 100vw;
    }
    .CoverCanvas > canvas {
        margin-left: 0px;
        width: 100vw !important;
        height: 100vw !important;
    }
    .CoverContent {
        width: auto;
        height: 100vw;
    }
    .CoverContentTitle {
        width: 100vw;
        padding: calc(50vw - 65px) 0px 10px 0px;
        text-align: center;
        font-size: 23px;
    }
    .big {
        font-size: 30px;
        margin-top: 10px;
        font-weight: bold;
    }
    .CoverContentButton {
        margin: 26px auto 0px auto;
        font-size: 14px;
    }
}