.GlobalRoot {
  height: calc(100vh - 60px);
  padding-top: 60px;
  overflow: hidden;
}

.GlobalUseCaseTitle, .GlobalClientsTitle {
  line-height: 4vh;
  font-size: 2vw;
  font-weight: 700;
  padding-top: 60px;
  color: #212121;
  text-align: center;
  font-family: 'GothamRounded Bold';
  margin-bottom: 4vh;
}

.GlobalUseCaseItemOuter {
  width: 20vw;
  height: calc(62vh - 60px);
  margin: 0px 1vw;
  position: relative;
  float: left;
}

.GlobalUseCaseItemFront {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 20vw;
  height: calc(47vh - 60px + 1vw);
}

.GlobalUseCaseItemTitle {
  font-size: 2vw;
  font-weight: 500;
  font-family: 'GothamRounded Bold';
  text-align: left;
  margin-bottom: 1vh;
}

.GlobalUseCaseItemParag {
  text-align: justify;
  font-size: 1.2vw;
  margin: 1vh;
  margin-bottom: 2vh;
  padding: 0.2vh 0.5vw 0.1vh 0.5vw;
  color: #666;
}

.GlobalUseCaseItemListItem {
  display: list-item;
  text-align: left;
  font-size: 1vw;
  padding: 0px;
  margin: 0px;
  margin-left: 4vw;
  margin-right: 2vw;
  color: #444;
}

.GlobalClientsItem {
  height: 16vh;
  width: 7vw;
  margin: 0px 1vw;
  display: inline-block;
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: all 0.3s;
}

.GlobalRoot > center { transform: scale(0.9); }

.GlobalPlayer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30;
  background-color: #0009;
}

.GlobalPlayer > video {
  width: 600px;
  height: 450px;
  position: fixed;
  top: calc(50vh - 225px);
  left: calc(50vw - 300px);
  background-color: #000;
  box-shadow: 4px 4px 22px rgba(0,0,0,0.8);
  z-index: 31;
  animation-name: GlobalPlayerFade;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
}

@keyframes GlobalPlayerFade {
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
}

.GlobalPlayerClose {
  background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 1);'><path d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'></path></svg>");
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
  z-index: 32;
  position: fixed;
  top: calc(50vh - 225px);
  left: calc(50vw + 250px);
  z-index: 32;
  cursor: pointer;
}

.GlobalUseCaseItemInner {
  height: calc(45vh - 60px);
  background-position: 2vw center;
  background-repeat: no-repeat;
  background-size: 15vw;
}

@media (orientation: landscape) {
  .GlobalUseCaseItemOuter {
    width: 48vw;
    padding: 2vw;
    font-weight: 400;
    margin-left: 4vw;
  }
  .GlobalUseCaseItemParag {
    margin-left: 20vw;
  }
  .GlobalUseCaseItemListItem {
    margin-left: 23vw;
  }
  .GlobalClientsItem:hover {
    filter: grayscale(0%);
    background-size: auto 80%;
    opacity: 1;
  }
  .GlobalUseCaseItemTitle {
    padding: 1vw;
  }
}

@media (orientation: portrait) {
  .GlobalRoot {
    height: auto;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .GlobalUseCaseTitle {
      font-size: 2.6vw;
      font-weight: 700;
      padding: 22px 0px 20px 0px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      color: #212121;
      margin-bottom: 1vh;
  }
  .GlobalUseCaseItemOuter {
    display: block;
    width: 80vw;
    overflow: hidden;
    float: none;
    height: auto;
    margin: 0px 1vw;
    border-radius: 1vw;
    margin-bottom: 1vh;
  }
  .GlobalUseCaseItemFront {
    height: 20px;
    line-height: 20px;
    width: auto;
    display: block;
    position: static;
    padding: 10px;
  }
  .GlobalUseCaseItemParag,
  .GlobalUseCaseItemListItem,
  .GlobalUseCaseItemBack {
    display: none;
  }
  .GlobalUseCaseItemTitle {
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    text-align: center;
    color: #FFF;
    margin-bottom: 1vh;
    text-align: left;
  }
  .GlobalUseCaseItemOuter:hover .GlobalUseCaseItemFront {
    transform: rotateY(0deg);
  }
  
  .GlobalUseCaseItemOuter:hover .GlobalUseCaseItemBack {
    transform: rotateY(180deg);
  }
  .GlobalClientsTitle {
      font-size: 20px;
      font-weight: 700;
      padding: 22px 0px 20px 0px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      color: #212121;
      margin-bottom: 0px;
  }
  .GlobalClientsItem {
    height: 30vw;
    width: 30vw;
    margin: 0px;
    display: inline-block;
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(100%);
    opacity: 0.8;
  }
  .GlobalRoot > center { transform: scale(1); }
  .GlobalPlayer > video {
    width: 100vw;
    height: 50vh;
    top: 25vh;
    left: 0px;
  }
  .GlobalPlayerClose {
    top: 25vh;
    left: calc(100vw - 50px);
  }
}

.GlobalUseCaseRoot {
  width: 62vw;
  float: left;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.GlobalClientsRoot {
  width: 30vw;
  float: left;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.GlobalClientsRoot > center {
  padding-top: calc(15vh - 60px);
}

@media (orientation: portrait) {
  .GlobalUseCaseRoot {
    display: none;
  }
  .GlobalClientsRoot {
    display: block;
    width: auto;
    height: auto;
    padding-top: 0px;
    float: none;
  }
  .GlobalClientsRoot > center {
    padding-top: 0px;
  }
}

.GlobalUseCaseButtons > button {
  background-color: transparent;
  color: #114392;
  border: 2px solid #114392;
  margin-left: 1vw;
  margin-top: 4vh;
  padding: 1.5vh 1.2vw;
  opacity: 0.65;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  font-size: 0.8vw;
}

.GlobalUseCaseButtons > button:hover {
  opacity: 0.85;
  background-color: #11439222;
}