.ContactRoot {
    padding: 2vw 4vw;
    background-color: #222;
    color: rgba(255,255,255,0.7);
    display: flex;
}

.ContactItem {
    line-height: 1.6vw;
    background-repeat: no-repeat;
    background-position: 0.3vw 0.3vw;
    background-size: 1.5vw 1.5vw;
    padding-left: 2.5vw;
    margin: 1vw 0px;
    font-size: 1vw;
}

.ContactItemAddress {
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 0.7);'><path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'></path></svg>");
}

.ContactItemMail {
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 0.7);'><path d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z'></path></svg>");
}

.ContactItemHotline {
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 0.7);'><path d='M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z'></path></svg>");
}

.ContactMail, .ContactInfo { width: 50vw; }

.ContactMailTitle {
    font-size: 2vw;
}

.ContactMailInner {
    padding: 1vw 2vw;
}

form > * {
    display: block;
    margin: 0px 0px 1vw;
    font-size: 0.85vw;
    width: 70%;
    padding: 0.7vw 0.7vw;
    border: none;
    background-color: #FFF2;
    color: #FFF;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    resize: none;
    border-radius: 0.4vw;
}

.ContactMailInner > input { height: 1.5vw; }
.ContactMailInner > textarea { height: 7vw; }

form > *::placeholder {
    color: #FFF6;
}

.ContactMailInner > button {
    height: 2.5vw;
    width: 8vw;
    background-color: #e73e2f;
    border: none;
    cursor: pointer;
    color: #FFF;
    font-size: 0.85vw;
    border-radius: 0.4vw;
}

@media (orientation: portrait) {
    .ContactRoot {
        padding: 1vw;
        display: block;
    }
    .ContactMail, .ContactInfo { width: auto; }
    .ContactItem {
        background-image: none !important;
        line-height: 20px;
        font-size: 14px;
        padding: 4px 35vw 4px 20px;
    }
    .ContactInfo, .ContactMail {
        padding: 10px 0px;
    }
    .ContactMailTitle {
        font-size: 20px;
        padding-left: 20px;
    }
    .ContactMailInner {
        padding: 20px 20px;
    }
    form > * {
        font-size: 13px;
        width: calc(100% - 20px);
        padding: 8px 10px;
        margin-bottom: 10px;
        border-radius: 6px;
    }
    form > textarea > input { height: 30px; }
    form > textarea { height: 100px; }
    .ContactMailInner > button {
        height: 40px;
        width: 120px;
        font-size: 13px;
        border-radius: 6px;
    }
}