.WhyRoot {
    background-color: rgba(0,0,0,0.06);
    transition: all 0.4s;
    height: 300vh;
}

.WhyTitle {
    line-height: 4vw;
    font-size: 2.6vw;
    font-weight: 700;
    padding-top: 30px;
    color: #212121;
    padding-left: calc(10vw);
	font-family: 'GothamRounded Bold';
    position: fixed;
    top: 10vh;
    left: 1vw;
}

.WhyInner {
    width: 81vw;
    margin: auto;
    padding-bottom: 2vh;
}

.WhySection {
    width: 63vw;
    display: inline-block;
    vertical-align: top;
    margin: 0px 2vw 2vw 35vw;
    height: 40vh;
    padding-top: 35vh;
}

.WhySectionImage {
    height: 80vh;
    width: 40vw;
    background-size: auto 60%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.85;
    vertical-align: middle;
    position: fixed;
    top: 15vh;
    left: 6vw;
    z-index: 20;
}

.WhySectionTitle {
    font-size: 1.6vw;
	font-family: 'GothamRounded Bold';
    line-height: 12vh;
}

.WhySectionItem {
    display: block;
    font-size: 1.1vw;
    margin-left: 25px;
}

.WhySectionItem::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(231, 62, 41, 1);'><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'></path></svg>");
    background-size: contain;
    background-repeat: no-repeat;
    content: ' ';
    vertical-align: middle;
    margin-left: -25px;
}

.WhySectionRoot {
    opacity: 0;
    transition: all 0.1s;
    overflow: hidden;
    visibility: hidden;
}

.WhySectionOn {
    opacity: 1;
    visibility: visible;
}




@media (orientation: portrait) {
    .WhyRoot { 
        height: auto;
    }
    .WhyTitle {
        font-size: 25px;
        text-align: center;
        padding: 50px 0px 0px 0px;
        position: initial;
    }
    .WhyInner {
        width: auto;
        margin: auto;
        padding: 20px;
    }
    .WhySection {
        width: auto;
        height: auto;
        display: block;
        padding: 0px 30px 30px 30px;
        margin: 0px;
    }
    .WhySectionImage {
        width: auto;
        height: 120px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-bottom: 20px;
        position: initial;
    }
    .WhySectionItem {
        font-size: 14px;
        margin-left: 25px;
    }
    .WhySectionItem::before {
        margin-left: -25px;
    }
}