.AboutRoot {
    height: calc(100vh - 60px);
    padding-top: 60px;
    background-color: rgb(221, 220, 219);
    background-image: url(images/ground.jpg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left;
}

.AboutRootInner { transform: scale(0.85); }

.AboutCanvas {
    position: absolute;
    height: inherit;
}

.AboutMobileImage {
    width: calc(48vh - 100px);
    height: calc(100vh - 200px);
    background-image: url(images/sofa.png);
    background-repeat: no-repeat;
    margin-top: 60px;
    background-size: auto 100%;
    animation-name: artest;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-duration: 4s;
}

.AboutMobileFrame {
    width: calc(48vh - 96px);
    height: calc(100vh - 200px);
    background-image: url(images/mobile_frame_2.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.AboutContent {
    position: relative;
    padding-left: calc(100vh - 100px);
}

.AboutContentTitle {
    color: #222;
    font-size: 2.8vw;
	font-family: 'GothamRounded Bold';
    line-height: 2vw;
    padding: 10vw 0px 30px 0px;
}

.AboutContentParag {
    width: calc(100vw - 100vh - 10px);
    font-size: 1.4vw;
}

@keyframes artest {
    0% {
        margin-left: calc(50vh - 50px);
        background-position: calc(100px - 50vh) 0px;
    }
    50% {
        margin-left: 50px;
        background-position: 0px 0px;
    }
    100% {
        margin-left: calc(50vh - 50px);
        background-position: calc(100px - 50vh) 0px;
    }
}

@media (orientation: portrait) {
    .AboutRoot {
        height: auto;
        background: #FFF;
        padding-top: 0px;
    }
    .AboutCanvas { display: none; }
    .AboutContent {
        padding: 10px;
        margin: 10px;
        position: initial;
    }
    .AboutContentTitle {
        padding: 20px 0px 30px 0px !important;
        font-size: 27px;
        margin: 0px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .AboutRootInner { transform: scale(1); }
    .AboutContentParag {
        width: auto;
        font-size: 18px;
        text-align: justify;
        padding-bottom: 6px;
    }
}

@media (max-width: 1000px) {
    .AboutRoot {
        height: auto;
        background-size: 100% 100%;
        background: #FFF;
    }
    .AboutCanvas { display: none; }
    .AboutContent {
        padding: 10px 20px 20px 20px;
        position: initial;
    }
    .AboutContentTitle {
        padding: 20px 0px;
        font-size: 27px;
        margin: 0px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .AboutContentParag {
        width: auto;
        font-size: 18px;
        text-align: justify;
        padding-bottom: 6px;
    }
}