.PortfolioRoot {
    height: calc(100vh - 60px);
    padding-top: 60px;
    background-color: rgb(221, 220, 219);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left;
}

.PortfolioTitle {
    line-height: 30px;
    font-size: 2.6vw;
    font-weight: 700;
    padding-top: 0vh;
    color: #212121;
    padding-left: calc(10vw);
	font-family: 'GothamRounded Bold';
}

.PortfolioInner {
    margin: 0px auto;
    height: calc(100vh - 170px);
    transform: scale(0.85);
}

.PortfolioInfo {
    width: calc(39vw - 120px);
    height: inherit;
    display: inline-block;
    vertical-align: top;
}

.PortfolioPlay {
    width: 48vw;
    height: calc(100vh - 330px);
    display: inline-block;
    vertical-align: top;
    margin: 0px 1.5vw;
}

.PortfolioPlay > video {
    width: 45vw;
    position: absolute;
    box-shadow: 0px 20px 80px rgba(0,0,0,0.6);
    transition-duration: 0.3s;
    transition-property: opacity, transform;
    margin-top: calc(50vh - 14vw - 70px);
    cursor: pointer;
}

.PortfolioButton {
    width: 6vh;
    height: 6vh;
    position: relative;
    z-index: 10;
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20vh;
    background-color: #FFF;
    cursor: pointer;
    opacity: 0.85;
    transform: scale(0.95);
    transition-duration: 0.3s;
    transition-property: opacity, transform;
}

.PortfolioButton:hover {
    opacity: 1;
    transform: scale(1);
}

.PortfolioButtonLast {
    left: 1vw;
    top: 47vh;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 20 20" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><polygon points="14.41,3.41 13,2 5,10 13,18 14.41,16.59 7.83,10"></polygon></g></svg>');
}

.PortfolioButtonNext {
    top: 41vh;
    left: calc(100vw - 9vh);
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 20 20" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(0, 0, 0); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><polygon points="4.59,16.59 6,18 14,10 6,2 4.59,3.41 11.17,10"></polygon></g></svg>');
}

.PortfolioName {
    font-size: 2vw;
    margin-top: 6vh;
    margin-left: 60px;
    margin-bottom: 10px;
    color: #114392;
    opacity: 0.85;
}

.PortfolioText {
    padding-top: 15px;
    margin-left: 60px;
    font-size: 1.1vw;
    max-height: calc(100vh - 400px);
    overflow-y: hidden;
}

.PortfolioDemoButton {
    background-color: transparent;
    color: #114392;
    border: 2px solid #114392;
    margin-left: 60px;
    margin-top: 35px;
    padding: 12px 20px;
    opacity: 0.65;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s;
}

.PortfolioDemoButton:hover {
    opacity: 0.85;
    background-color: #11439222;
}

.PortfolioCallButton {
    background-color: transparent;
    color: #114392;
    border: 2px solid #114392;
    margin-left: 60px;
    margin-top: 15px;
    padding: 12px 20px;
    opacity: 0.65;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s;
}

.PortfolioCallButton:hover {
    opacity: 0.85;
    background-color: #11439222;
}

@media (min-width: 1200px) {
    .PortfolioCallButton {
        margin-left: 15px;
    }
}

@media (orientation: portrait) {
    .PortfolioPlay {
        display: none;
    }
    .PortfolioButton { 
        display: none;
    }
    .PortfolioRoot {
        height: auto;
        padding-top: 0px;
        background-color: #FFF;
    }
    .PortfolioInner {
        height: auto;
    }
    .PortfolioTitle {
        font-size: 2.6vw;
        font-weight: 700;
        padding: 0px 0px 20px 0px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: #212121;
    }
    .PortfolioInner {
        margin: 0px;
        width: auto;
        transform: scale(1);
    }
    .PortfolioItem {
        width: auto;
        display: block;
        height: 100px;
        margin: 0px;
        background-position: center;
        background-size: 100% auto;
        filter: grayscale(60%);
        opacity: 0.8;
    
    }
    .PortfolioItemTitle {
        font-size: 20px;
        line-height: 20px;
        line-height: 100px;
        background-color: rgba(0,0,0,0.8);
        text-align: center;
        color: #FFF;
    }

}

.PortfolioInnerDesktop {
    display: flex;
}

.PortfolioInner video {
    height: calc(80vh - 170px);
    border-radius: 3vh;
    box-shadow: 0px 1vh 3vw #1113;
    opacity: 0;
    transition-duration: 0.2s;
    transition-property: opacity, box-shadow, transform;
}

.PortfolioVideoInner {
    height: calc(80vh - 170px);
    margin: 3vh 1vh 0px 1vh;
    border-radius: 3vh;
    opacity: 0.9;
    transform: scale(0.95);
    transition-duration: 0.2s;
    transition-property: opacity, box-shadow, transform;
    cursor: pointer;
}

.PortfolioVideoInner:hover {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0px 2vh 3vw #1117;
}

.PortfolioVideoInner:hover > video {
    opacity: 1;
}

.PortfolioVideoInner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PortfolioInner {
    transition-duration: 0.75s;
    transition-property: margin-left;
}

.PortfolioVideoInfo {
    font-size: 1.6vw;
    height: 22vh;
    margin-top: -22.3vh;
    z-index: 3;
    position: relative;
    border-radius: 0vh 0vh 3vh 3vh;
    background-image: linear-gradient(transparent, #1116, #111E);
    padding: 0px 2vw;
    color: #FFF;
    line-height: 32.2vh;
    opacity: 0;
    transition-duration: 0.2s;
    transition-property: opacity, box-shadow, transform;
}

.PortfolioVideoInner:hover > .PortfolioVideoInfo {
    opacity: 1;
}

.PortfolioInner[index="0"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * 0);
}

.PortfolioInner[index="1"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * -0.95);
}

.PortfolioInner[index="2"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * -1.9);
}

.PortfolioInner[index="3"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * -2.9);
}

.PortfolioInner[index="4"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * -3.85);
}

.PortfolioInner[index="5"] {
    margin-left: calc(calc(calc(calc(80vh - 170px) * 1.7777777777777777) - 2vh) * -4.8);
}

.PortfolioRoot[index="0"] > .PortfolioButtonLast {
    opacity: 0;
    cursor: default;
}

.PortfolioRoot[index="5"] > .PortfolioButtonNext {
    opacity: 0;
    cursor: default;
}

.PortfolioVideoLink {
    float: right;
    width: 7vh;
    height: 7vh;
    margin-top: 12vh;
    background-image: url('data:image/svg+xml,<svg version="1.1" viewBox="0 0 24 24" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: rgb(255, 255, 255); opacity: 1; transform: rotate(0deg) scale(0.7, 0.7);"><g><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></g></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    opacity: 0.65;
    transition-duration: 0.2s;
    transition-property: all;
}

.PortfolioVideoLink:hover {
    opacity: 1;
}