* {
	user-select: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	text-decoration: none;
	scroll-behavior: unset;
}

body {
	background-color: #FFF;
	margin: 0px;
	overflow-x: hidden;
}


*::-webkit-scrollbar { width: 5px; height: 5px; background: #111; }
*::-webkit-scrollbar-thumb { background: #06c; }
*::-webkit-scrollbar-corner { background: transparent; }
*::-webkit-scrollbar-thumb { background: #06c; }