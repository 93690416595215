.ChatBotHover {
    width: 240px;
    height: 65px;
    background-color: #111;
    z-index: 12;
    cursor: pointer;
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 1);'><path d='M20,2H4C2.897,2,2,2.894,2,3.992v12.016C2,17.106,2.897,18,4,18h3v4l6.351-4H20c1.103,0,2-0.894,2-1.992V3.992 C22,2.894,21.103,2,20,2z'></path></svg>");
    background-size: 25px;
    background-position: 20px 22px;
    background-repeat: no-repeat;
    transition: 0.1s background;
    transition-duration: 0.3s;
    transition-property: all;

    line-height: 65px;
    padding-left: 60px;
    color: transparent;
    overflow: hidden;
}

.ChatBotHoverOn {
    color: #FFF;
}

.ChatBotHoverClose {
    width: 65px;
    height: 65px;
    float: right;
    background-image: url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(255, 255, 255, 1);'><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path></svg>");
    background-size: 28px;
    background-position: center;
    background-repeat: no-repeat;
    transition-duration: 0.1s;
    transition-property: all;
}

.ChatBotHoverClose:hover {
    background-color: rgba(255,255,255,0.02);
}

.ChatBotOuter {
    width: 65px;
    height: 65px;
    top: calc(100vh - 90px);
    left: calc(100vw - 90px);
    position: fixed;
    z-index: 11;
    background-color: #111;
    border-radius: 32.5px;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: top, left, height, width, border-radius;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.35);
}

.ChatBotOuterOn {
    top: calc(100vh - 505px);
    left: calc(100vw - 325px);
    height: 480px;
    width: 300px;
    border-radius: 15px;
}

.ChatBotInner {
    height: 415px;
}

.ChatBotTray {
    height: calc(355px - 68px);
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    opacity: 0;
    transition-duration: 0.2s;
    transition-property: opacity, height;
    background-color: #1e1e1e;
}

.ChatBotOuterOn > .ChatBotInner > .ChatBotTray {
    opacity: 1;
}

.ChatBotTray::-webkit-scrollbar {
	width: 5px; height: 5px;
	background: #222;
}
.ChatBotTray::-webkit-scrollbar-thumb { background: #555; }
::-webkit-scrollbar-corner { background: transparent; }
.ChatBotTray::-webkit-scrollbar-thumb { background: #555; }

.ChatBotSelectBox {
    height: 58px;
    padding: 5px;
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: height, padding;
}

.ChatBotSelect {
    display: inline-block;
    font-size: 11px;
    padding: 5px 8px;
    border-radius: 12px;
    color: #FFF;
    background-color: rgba(255,255,255,0.2);
    margin: 0px 5px 5px 0px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: opacity;
    opacity: 0.6;
}

.ChatBotSelect:hover {
    opacity: 1;
}

.ChatBotInnerInput > .ChatBotTray {
    height: 355px;
}

.ChatBotInnerInput > .ChatBotSelectBox {
    height: 0px;
    padding: 0px;
}

.ChatBotText {
    height: 50px;
    width: 280px;
    border: none;
    background-color: rgba(255,255,255,0.1);
    padding: 0px 20px;
    font-size: 14px;
    font-weight: bold;
    color: #EEE;
}

.ChatBotUserMessageOuter {
    min-height: 47px;
}

.ChatBotUserMessage {
    background-color: #AAA;
    margin: 5px 8px 4px 8px;
    border-radius: 8px;
    padding: 8px 12px;
    float: right;
    font-size: 14px;
    color: #222;
    max-width: 200px;
    font-weight: bold;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ChatBotAIMessage {
    background-color: #b60606;
    margin: 5px 8px 4px 8px;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    color: #FFF;
    max-width: 200px;
    display: inline-block;
    line-height: 20px;
}

@media (orientation: portrait) {
    .ChatBotOuter {
        top: calc(100vh - 150px);
        transform: scale(0.85);
    }
    .ChatBotOuterOn {
        top: calc(100vh - 535px);
        left: calc(100vw - 310px);
        height: 480px;
        width: 300px;
        border-radius: 15px;
    }
}