.StartedRoot {
  height: calc(100vh - 60px);
  padding-top: 60px;
  background-image: url(images/ground_3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.StartedTitle {
  line-height: 6vh;
  font-size: 2.6vw;
  font-weight: 700;
  padding-top: 40px;
  color: #212121;
  padding-left: calc(10vw);
  font-family: 'GothamRounded Bold';
}

.StartedSubTitle {
  line-height: 5vh;
  font-size: 2.2vw;
  padding-left: calc(10vw);
  color: #114392;
  margin-bottom: 3vh;
  color: #212121;
  font-size: 1.1vw;
  font-family: 'GothamRounded Bold';
}

.StartedItem {
  width: 18vw;
  height: 10vh;
  padding: 18vh 3vw 2vh 3vw;
  display: inline-block;
  background-size: 6vw auto;
  background-position: center 4vh;
  background-repeat: no-repeat;
  margin: 0vh 1vw 3vh 1vw;
  background-color: #FFF1;
  color: #eee;
  overflow: hidden;
  filter: invert(100%);
  transition: all 0.3s;
  font-weight: 500;
  border-radius: 3vh;
  font-size: 1.1vw;
  opacity: 0.85;
  transform: scale(0.95);
}

.StartedRoot > center { transform: scale(0.9); }

@media (orientation: landscape) {
  .StartedItem:hover {
    background-position: center 3vh;
    background-size: 7vw auto;
    background-color: #000;
    filter: invert(0%);
    transform: scale(1.2);
    box-shadow: 0px 6px 20px rgba(0,0,0,0.5);
    border-radius: 3vh;
  }
}

@media (orientation: portrait) {
  .StartedRoot {
    height: auto;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .StartedTitle {
      font-size: 2.6vw;
      font-weight: 700;
      padding: 22px 0px 20px 0px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      color: #212121;
  }
  .StartedRoot > center { transform: scale(1); }
  .StartedSubTitle { display: none; }
  .StartedItem {
    width: 35vw;
    height: 18vw;
    padding: 25vw 12px 30px 12px;
    margin: 10px 8px;
    font-size: 14px;
    font-weight: normal;
    background-size: auto 15vw;
  }
}